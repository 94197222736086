import { Component } from "@angular/core";
import { Validators, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { SharedLoginService } from "./shared-login.service";
import { AppService } from "src/app/app.service";

@Component({
    selector: "app-shared-login",
    templateUrl: "./shared-login.component.html"
})
export class SharedLoginComponent {
    form = new FormGroup({
        password: new FormControl<string>("", [Validators.required])
    });
    isLoading = false;
    isFormSubmitted = false;

    constructor(
        private sharedLoginService: SharedLoginService,
        private router: Router,
        public appService: AppService
    ) {}

    async onSubmit() {
        this.isFormSubmitted = true;
        if (this.form.invalid) return;
        this.isLoading = true;
        const sharedPageRoute = await this.sharedLoginService.login(this.form.controls.password.value);
        if (sharedPageRoute && this.sharedLoginService.isLoggedIn) {
            this.router.navigate([sharedPageRoute]);
        }
        this.isLoading = false;
    }
}
